<template>
  <v-layout full-height class="app-bg">
    <v-main>
      <slot></slot>
    </v-main>
    <GlobalAppFooter />
  </v-layout>
</template>

<style lang="scss">
   .app-bg {
      background-color: $brand-secondary !important;
   }
</style>
